import Fuse from 'fuse.js'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ListItemButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ListItemButton'

import lookingGlassIcon from '/images/icons/looking-glass.raw.svg'
import styles from './SkillsWithSearchbar.css'

export function SkillsWithSearchbar({ layoutClassName = undefined, options }) {
  const initialOptions = React.useMemo(
    () => {
      return Object.entries(options ?? {}).map(([id, item]) => ({ id, ...item }))
    },
    [options]
  )

  const { __ } = useTranslate()
  const [searchValue, setSearchValue] = React.useState('')
  const { searchResults } = useFuseSearch({ options: initialOptions, searchValue })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <HeadingSm h='2' title={__`skills-match-skills-all`} layoutClassName={styles.headingLayout} />
      <SearchInput
        placeholder={__`search-for-skills`}
        onChange={handleChange}
        {...{ searchValue }}
      />

      {Boolean(searchResults?.length) && (
        <div className={styles.searchResultsGrid}>
          {searchResults.map(({ id, label, isSelected }, i) => (
            <ListItemButton key={i} {...{ id, label, isSelected }} />
          ))}
        </div>
      )}
    </div>
  )

  function handleChange(e) {
    setSearchValue(e.target.value)
  }
}

function SearchInput({ placeholder, searchValue, onChange }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentSearchInput}>
      <div className={styles.icon}>
        <Icon icon={lookingGlassIcon} />
      </div>
      <input
        className={styles.input}
        type='text'
        aria-label={__`topics-search-field-placeholder`}
        value={searchValue}
        {...{ placeholder, onChange }}
      />
    </div>
  )
}

function useFuseSearch({ options, searchValue }) {
  const fuse = React.useMemo(
    () => new Fuse(options, {
      keys: ['label'],
      threshold: 0.3,
    }),
    [options]
  )

  const searchResults = searchValue
    ? fuse.search(searchValue).map(({ item }) => item)
    : options

  return { searchResults }
}
